import { computed, makeObservable, observable } from "mobx";
import * as endpoint from "Frontend/generated/UserEndpoint";
import { BaseDatastore } from "Frontend/store/base-datastore";
import User from "Frontend/generated/de/gemons/core/entities/waitress/User";
import UserRegistration from "Frontend/generated/de/gemons/core/entities/waitress/UserRegistration";
import { EndpointError } from "@vaadin/hilla-frontend";

export class UserDatastore extends BaseDatastore {
    _users: Map<number,User> = new Map();
    heartbeatTimer?: number | undefined;

    constructor() {
        super()
        makeObservable(this, {
            _users: observable,
            users: computed
        });
   }

    public init() {
        this.fetchAll()
        this.startHeartBeat()
   }

    get users(): User[] {
        return Array.from(this._users.values())
    }

    protected fetch(id: number): void {
        //Not Supported
    }

    protected fetchAll(): void {
        const process_id = this.addToProcessing('user fetch all')
        endpoint.getAllUsernameOnly(undefined, undefined, undefined)
            .then(entries => {
                if (entries) {
                    for (let entry of entries) {
                        this.addToData(entry as User)
                    }
                }
            })
            .finally(() => this.removeProcess(process_id));
    }

    protected addToData(entry: User) {
        // @ts-ignore
        if (entry?.id != null) {
            // @ts-ignore
            this._users.set(entry.id, entry)
        }
    }

    saveAll (obj: User[]) : void {
        // Not supported
    }

    save (obj: User) : void {
        // Not supported
    }

    private startHeartBeat() {
        console.debug("Setting heartbeat")
        this.heartbeatTimer = setInterval(endpoint.heartbeat, 10 * 60 * 1000 );
    }

    public resetHeartBeat() {
        if (this.heartbeatTimer) {
            console.debug("Resetting heartbeat", this.heartbeatTimer)
            clearInterval(this.heartbeatTimer);
            this.startHeartBeat()
        }
    }

    register(obj: UserRegistration): Promise<undefined> {
        const process_id = this.addToProcessing()
        return new Promise<undefined>((resolve, reject) => {
            endpoint.register(obj)
                .then(r => resolve(undefined))
                .catch((e: EndpointError) => reject(e))
                .finally(() => this.removeProcess(process_id))
        })
    }
}

export const userStore = new UserDatastore()
import { BaseDatastore } from "Frontend/store/base-datastore";
import { makeObservable, observable } from "mobx";
import Notification from "Frontend/generated/de/gemons/core/entities/waitress/Notification";
import * as endpoint from "Frontend/generated/NotificationEndpoint";

export class NotificationDatastore extends BaseDatastore {
    _notifications: Notification[] = []

    constructor() {
        super()
        makeObservable(this, {
            _notifications: observable
        });
    }

    public async init() {
        await this.fetchAll()
    }

    public save(notification: Notification): Promise<Notification> {
        const processId = this.addToProcessing("notification save")
        return new Promise<Notification>((resolve, reject) => {
            endpoint.save(notification)
                .then((r) => {
                    if (r) {
                        this._notifications.push(r);
                    }
                    resolve(r || {})
                })
                .catch((e) => reject(e))
                .finally(() => this.removeProcess(processId))
        });
    }

    public async fetchAll(): Promise<Notification[]> {
        const processId = this.addToProcessing("notification getAll")
        return new Promise<Notification[]>((resolve, reject) => {
            endpoint.getForUser()
                .then((r) => {
                    if (r) {
                        this._notifications = (r as Notification[])
                    }
                    resolve(this._notifications)
                })
                .catch((e) => reject(e))
                .finally(() => {
                    this.removeProcess(processId)
                })
        });
    }

    public accept(notification: Notification):Promise<boolean> {
        const processId = this.addToProcessing("notification accept")
        return new Promise<boolean>((resolve, reject) => {
            endpoint.accept(notification)
                .then((r) => {
                    const index = this._notifications.findIndex(n => n.id === notification.id);
                    if (index !== -1) {
                        this._notifications.splice(index, 1);
                    }
                    resolve(true);
                })
                .catch((e) => reject(e))
                .finally(() => this.removeProcess(processId));
        });
    }

    public decline(notification: Notification):Promise<boolean> {
        const processId = this.addToProcessing("notification decline")
        return new Promise<boolean>((resolve, reject) => {
            endpoint.decline(notification)
                .then((r) => {
                    const index = this._notifications.findIndex(n => n.id === notification.id);
                    if (index !== -1) {
                        this._notifications.splice(index, 1);
                    }
                    resolve(true);
                })
                .catch((e) => reject(e))
                .finally(() => this.removeProcess(processId));
        });
    }
}

export const notificationStore = new NotificationDatastore()
import { EndpointRequestInit as EndpointRequestInit_1 } from "@vaadin/hilla-frontend";
import type QuerySortOrder_1 from "./com/vaadin/flow/data/provider/QuerySortOrder.js";
import client_1 from "./connect-client.default.js";
import type User_1 from "./de/gemons/core/entities/waitress/User.js";
import type UserRegistration_1 from "./de/gemons/core/entities/waitress/UserRegistration.js";
import type UserInfo_1 from "./de/gemons/market/backend/security/UserInfo.js";
async function getAllUsernameOnly_1(arg0: number | undefined, arg1: number | undefined, arg2: Array<QuerySortOrder_1 | undefined> | undefined, init?: EndpointRequestInit_1): Promise<Array<User_1 | undefined> | undefined> { return client_1.call("UserEndpoint", "getAllUsernameOnly", { arg0, arg1, arg2 }, init); }
async function getCurrentUserId_1(init?: EndpointRequestInit_1): Promise<number | undefined> { return client_1.call("UserEndpoint", "getCurrentUserId", {}, init); }
async function getUserInfo_1(init?: EndpointRequestInit_1): Promise<UserInfo_1 | undefined> { return client_1.call("UserEndpoint", "getUserInfo", {}, init); }
async function heartbeat_1(init?: EndpointRequestInit_1): Promise<void> { return client_1.call("UserEndpoint", "heartbeat", {}, init); }
async function register_1(arg0: UserRegistration_1 | undefined, init?: EndpointRequestInit_1): Promise<boolean> { return client_1.call("UserEndpoint", "register", { arg0 }, init); }
export { getAllUsernameOnly_1 as getAllUsernameOnly, getCurrentUserId_1 as getCurrentUserId, getUserInfo_1 as getUserInfo, heartbeat_1 as heartbeat, register_1 as register };
